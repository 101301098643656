import React, { useEffect, useRef, useState } from 'react';
import { graphql } from "gatsby"
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import parse from 'html-react-parser';

import Layout from "../../components/layout"
import Seo from "../../components/seo"

//sections
import CookinGenieHeroSection from "../sections/caseStudies/cookinGenie/CookinGenieHeroSection"
import ChallengesSection from "../sections/caseStudies/ChallengesSection"
import CookingenieSectionSketches from '../sections/caseStudies/cookinGenie/CookinGenieSectionSketches';
import CookingenieWireframesSection from '../sections/caseStudies/cookinGenie/CookingenieWireframesSection';
import CookingenieSection1 from "../sections/caseStudies/cookinGenie/CookingenieSection1"
import CookingenieSection2 from "../sections/caseStudies/cookinGenie/CookingenieSection2"
import CookingenieComunicationSection from '../sections/caseStudies/cookinGenie/CookingenieComunicationSection';
import CookingenieSection3 from "../sections/caseStudies/cookinGenie/CookingenieSection3"
import CookingenieSection4 from '../sections/caseStudies/cookinGenie/CookingenieSection4';
// import TestimonialSectionWithoutBG from "../sections/TestimonialSectionWithoutBG";


const Cookingenie = ({ data }) => {
  const mainData = data.allWpPage.nodes[0].caseStudyCookingeniePageACF;
  const sectionRef = useRef();
  const [bgTransition, setBgTransition] = useState('');

  // useEffect(() => {
  //   let unmounted = false;
  //   gsap.registerPlugin(ScrollTrigger)
  //   setTimeout(() => {
  //     if (!unmounted) {
  //       gsap.set(sectionRef.current, {
  //         scrollTrigger: {
  //           trigger: '.cookingenie-case-study .cookingenie-sketches-section',
  //           start: "top 60%",
  //           end: "bottom 60%",
  //           ease: "ease",
  //           scrub: true,
  //           markers: false,
  //           onEnter: () => setBgTransition('innago-bg-2'),
  //           onLeave: () => setBgTransition(''),
  //           onEnterBack: () => setBgTransition('innago-bg-2'),
  //           onLeaveBack: () => setBgTransition(''),
  //         }
  //       });
  //     }
  //   }, 1000);

  //   return () => {
  //     unmounted = true;
  //   }
  // }, []);
  return (
    <Layout>
      <Seo title="CookinGenie" />
      <div className={"cookingenie-case-study page-wrapper " + bgTransition} id="cookingenie-casestudy-page-wrapper" ref={sectionRef}>
        <CookinGenieHeroSection
          preTitle={parse(mainData.cookingenieHeroSectionPretitle)}
          mainTitle={parse(mainData.cookingenieHeroSectionTitle)}
          image1x={
            {
              sourceUrl: mainData.cookingenieHeroSectionImage1x.sourceUrl,
              altText: mainData.cookingenieHeroSectionImage1x.altText,
              width: mainData.cookingenieHeroSectionImage1x.width,
            }
          }
          image2x={
            {
              sourceUrl: mainData.cookingenieHeroSectionImage2x.sourceUrl,
              altText: mainData.cookingenieHeroSectionImage2x.altText,
              width: mainData.cookingenieHeroSectionImage2x.width,
            }
          }
          mobileImage={
            {
              sourceUrl: mainData.cookingenieHeroSectionMobileImage.sourceUrl,
              altText: mainData.cookingenieHeroSectionMobileImage.altText,
              width: mainData.cookingenieHeroSectionMobileImage.width,
            }
          }
        />
        <ChallengesSection
          companyInfoList={mainData.cookingenieChallengeSectionCompanyInfoList}
          challengesList={mainData.cookingenieChallengeSectionChallangesList}
        />
        <CookingenieSectionSketches 
          mainTitle={parse(mainData.cookingenieSketchesSectionTitle)}
          mainDescription={parse(mainData.cookingenieSketchesSectionDescription)}
          image1x={
            {
              sourceUrl: mainData.cookingenieSketchesSectionImage11x.sourceUrl,
              altText: mainData.cookingenieSketchesSectionImage11x.altText,
              width: mainData.cookingenieSketchesSectionImage11x.width,
            }
          }
          image2x={
            {
              sourceUrl: mainData.cookingenieSketchesSectionImage12x.sourceUrl,
              altText: mainData.cookingenieSketchesSectionImage12x.altText,
              width: mainData.cookingenieSketchesSectionImage12x.width,
            }
          }
        />
        <CookingenieWireframesSection 
          mainTitle={parse(mainData.cookingenieWireframesSectionTitle)}
          mainDescription={parse(mainData.cookingenieWireframesSectionDescription)}
          image1x={
            {
              sourceUrl: mainData.cookingenieWireframesSectionImage1x.sourceUrl,
              altText: mainData.cookingenieWireframesSectionImage1x.altText,
              width: mainData.cookingenieWireframesSectionImage1x.width,
            }
          }
          image2x={
            {
              sourceUrl: mainData.cookingenieWireframesSectionImage2x.sourceUrl,
              altText: mainData.cookingenieWireframesSectionImage2x.altText,
              width: mainData.cookingenieWireframesSectionImage2x.width,
            }
          }
        />
        <CookingenieSection1
          mainTitle={parse(mainData.cookingenieSection1Title)}
          mainDescription={parse(mainData.cookingenieSection1Description)}
          image11x={
            {
              sourceUrl: mainData.cookingenieSection1Image11x.sourceUrl,
              altText: mainData.cookingenieSection1Image11x.altText,
              width: mainData.cookingenieSection1Image11x.width,
            }
          }
          image12x={
            {
              sourceUrl: mainData.cookingenieSection1Image12x.sourceUrl,
              altText: mainData.cookingenieSection1Image12x.altText,
              width: mainData.cookingenieSection1Image12x.width,
            }
          }
          image21x={
            {
              sourceUrl: mainData.cookingenieSection1Image21x.sourceUrl,
              altText: mainData.cookingenieSection1Image21x.altText,
              width: mainData.cookingenieSection1Image21x.width,
            }
          }
          image22x={
            {
              sourceUrl: mainData.cookingenieSection1Image22x.sourceUrl,
              altText: mainData.cookingenieSection1Image22x.altText,
              width: mainData.cookingenieSection1Image22x.width,
            }
          }
          image31x={
            {
              sourceUrl: mainData.cookingenieSection1Image31x.sourceUrl,
              altText: mainData.cookingenieSection1Image31x.altText,
              width: mainData.cookingenieSection1Image31x.width,
            }
          }
          image32x={
            {
              sourceUrl: mainData.cookingenieSection1Image32x.sourceUrl,
              altText: mainData.cookingenieSection1Image32x.altText,
              width: mainData.cookingenieSection1Image32x.width,
            }
          }
          image41x={
            {
              sourceUrl: mainData.cookingenieSection1Image41x.sourceUrl,
              altText: mainData.cookingenieSection1Image41x.altText,
              width: mainData.cookingenieSection1Image41x.width,
            }
          }
          image42x={
            {
              sourceUrl: mainData.cookingenieSection1Image42x.sourceUrl,
              altText: mainData.cookingenieSection1Image42x.altText,
              width: mainData.cookingenieSection1Image42x.width,
            }
          }
          image51x={
            {
              sourceUrl: mainData.cookingenieSection1Image51x.sourceUrl,
              altText: mainData.cookingenieSection1Image51x.altText,
              width: mainData.cookingenieSection1Image51x.width,
            }
          }
          image52x={
            {
              sourceUrl: mainData.cookingenieSection1Image52x.sourceUrl,
              altText: mainData.cookingenieSection1Image52x.altText,
              width: mainData.cookingenieSection1Image52x.width,
            }
          }
        />
        <CookingenieSection2
          // mainTitle={parse(mainData.cookingenieSection2Title)}
          typography={
            {
              sourceUrl: mainData.cookingenieSection2Typography.sourceUrl,
              altText: mainData.cookingenieSection2Typography.altText,
              width: mainData.cookingenieSection2Typography.width,
            }
          }
          imagesList={mainData.cookingenieSection2ImageList}
        />
        <CookingenieComunicationSection 
          mainTitle={parse(mainData.cookingenieComunicationSectionTitle)}
          mainDescription={parse(mainData.cookingenieComunicationSectionDescription)}
          image1x={
            {
              sourceUrl: mainData.cookingenieComunicationSectionImage1x.sourceUrl,
              altText: mainData.cookingenieComunicationSectionImage1x.altText,
              width: mainData.cookingenieComunicationSectionImage1x.width,
            }
          }
          image2x={
            {
              sourceUrl: mainData.cookingenieComunicationSectionImage2x.sourceUrl,
              altText: mainData.cookingenieComunicationSectionImage2x.altText,
              width: mainData.cookingenieComunicationSectionImage2x.width,
            }
          }
        />
        <CookingenieSection3
          mainTitle={parse(mainData.cookingenieSection3Title)}
          imagesList={mainData.cookingenieSection3ImageList}
        />
        <CookingenieSection4 
          image11x={
            {
              sourceUrl: mainData.cookingenieSection4Image11x.sourceUrl,
              altText: mainData.cookingenieSection4Image11x.altText,
              width: mainData.cookingenieSection4Image11x.width,
            }
          }
          image12x={
            {
              sourceUrl: mainData.cookingenieSection4Image12x.sourceUrl,
              altText: mainData.cookingenieSection4Image12x.altText,
              width: mainData.cookingenieSection4Image12x.width,
            }
          }
          image21x={
            {
              sourceUrl: mainData.cookingenieSection4Image21x.sourceUrl,
              altText: mainData.cookingenieSection4Image21x.altText,
              width: mainData.cookingenieSection4Image21x.width,
            }
          }
          image22x={
            {
              sourceUrl: mainData.cookingenieSection4Image22x.sourceUrl,
              altText: mainData.cookingenieSection4Image22x.altText,
              width: mainData.cookingenieSection4Image22x.width,
            }
          }
          image31x={
            {
              sourceUrl: mainData.cookingenieSection4Image31x.sourceUrl,
              altText: mainData.cookingenieSection4Image31x.altText,
              width: mainData.cookingenieSection4Image31x.width,
            }
          }
          image32x={
            {
              sourceUrl: mainData.cookingenieSection4Image32x.sourceUrl,
              altText: mainData.cookingenieSection4Image32x.altText,
              width: mainData.cookingenieSection4Image32x.width,
            }
          }
        />
        {/* <TestimonialSectionWithoutBG
          extraClasses=""
          mainTitle={parse(mainData.cookingenieTestimonialSectionTitle)}
          message={parse(mainData.cookingenieTestimonialSectionDescription)}
          name={parse(mainData.cookingenieTestimonialSectionName)}
          designation={parse(mainData.cookingenieTestimonialSectionDesignation)}
          headshot1x={
            {
              sourceUrl: mainData.cookingenieTestimonialSectionHeadshot1x.sourceUrl,
              altText: mainData.cookingenieTestimonialSectionHeadshot1x.altText,
              width: mainData.cookingenieTestimonialSectionHeadshot1x.width,
            }
          }
          headshot2x={
            {
              sourceUrl: mainData.cookingenieTestimonialSectionHeadshot2x.sourceUrl,
              altText: mainData.cookingenieTestimonialSectionHeadshot2x.altText,
              width: mainData.cookingenieTestimonialSectionHeadshot2x.width,
            }
          }
          logo={
            {
              sourceUrl: mainData.cookingenieTestimonialSectionLogo.sourceUrl,
              altText: mainData.cookingenieTestimonialSectionLogo.altText,
              width: mainData.cookingenieTestimonialSectionLogo.width,
            }
          }
        /> */}
      </div>
    </Layout>
  )
}

export const CookingenieQuery = graphql`
{
  allWpPage(filter: {slug: {eq: "cookingenie"}}) {
    nodes {
      caseStudyCookingeniePageACF {
        cookingenieHeroSectionPretitle
        cookingenieHeroSectionTitle
        cookingenieHeroSectionImage1x {
          altText
          sourceUrl
          width
        }
        cookingenieHeroSectionImage2x {
          altText
          sourceUrl
          width
        }
        cookingenieHeroSectionMobileImage {
          altText
          sourceUrl
          width
        }
        cookingenieChallengeSectionCompanyInfoList{
          title
          description
        }
        cookingenieChallengeSectionChallangesList{
          title
          description
        }
        cookingenieSketchesSectionTitle
        cookingenieSketchesSectionDescription
        cookingenieSketchesSectionImage11x {
          altText
          sourceUrl
          width
        }
        cookingenieSketchesSectionImage12x {
          altText
          sourceUrl
          width
        }
        cookingenieWireframesSectionTitle
        cookingenieWireframesSectionDescription
        cookingenieWireframesSectionImage1x {
          altText
          sourceUrl
          width
        }
        cookingenieWireframesSectionImage2x {
          altText
          sourceUrl
          width
        }
        cookingenieSection1Title
        cookingenieSection1Description
        cookingenieSection1Image11x {
          altText
          sourceUrl
          width
        }
        cookingenieSection1Image12x {
          altText
          sourceUrl
          width
        }
        cookingenieSection1Image21x {
          altText
          sourceUrl
          width
        }
        cookingenieSection1Image22x {
          altText
          sourceUrl
          width
        }
        cookingenieSection1Image31x {
          altText
          sourceUrl
          width
        }
        cookingenieSection1Image32x {
          altText
          sourceUrl
          width
        }
        cookingenieSection1Image41x {
          altText
          sourceUrl
          width
        }
        cookingenieSection1Image42x {
          altText
          sourceUrl
          width
        }
        cookingenieSection1Image51x {
          altText
          sourceUrl
          width
        }
        cookingenieSection1Image52x {
          altText
          sourceUrl
          width
        }
        cookingenieSection2Typography {
          altText
          sourceUrl
          width
        }
        cookingenieSection2ImageList {
          image1x {
            altText
            sourceUrl
            width
          }
          image2x {
            altText
            sourceUrl
            width
          }
        }
        cookingenieComunicationSectionTitle
        cookingenieComunicationSectionDescription
        cookingenieComunicationSectionImage1x {
          altText
          sourceUrl
          title
        }
        cookingenieComunicationSectionImage2x {
          altText
          sourceUrl
          width
        }
        cookingenieSection3Title
        cookingenieSection3ImageList {
          image1x {
            altText
            sourceUrl
            width
          }
          image2x {
            altText
            sourceUrl
            width
          }
        }

        cookingenieSection4Image11x {
          altText
          sourceUrl
          width
        }
        cookingenieSection4Image12x {
          altText
          sourceUrl
          width
        }
        cookingenieSection4Image21x {
          altText
          sourceUrl
          width
        }
        cookingenieSection4Image22x {
          altText
          sourceUrl
          width
        }
        cookingenieSection4Image31x {
          altText
          sourceUrl
          width
        }
        cookingenieSection4Image32x {
          altText
          sourceUrl
          width
        }

        
      }
    }
  }
}
`

export default Cookingenie
